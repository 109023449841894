@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
}

html,
body {
  font-family: "Roboto Mono", monospace;
  color: white;
  overflow-x: hidden;
}

header {
  background-color: #151618;
  height: 100vh;
  display: flex;
  flex-flow: column;
  position: relative;
}

nav {
  background-color: #151618;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-right: 6rem;
  padding-left: 6rem;
  display: flex;
  justify-content: space-between;
}

nav ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

nav ul a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: medium;
  padding: 1rem;
  transition: all 0.3s ease-in-out;
}

a {
  color: inherit;
  text-decoration: none;
}

.rain-logo {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
}

.rain-logo .big-logo {
  height: 40vh;
}

.nav-logo {
  height: 30px;
}

.header-infographic {
  position: absolute;
  bottom: 4rem;
  right: 4rem;
  height: 2rem;
}

.services {
  padding-top: 6rem;
  padding-bottom: 12rem;
  background-color: #1a1b1e;
  border-top: 1px solid #4f4f4f;
  border-bottom: 1px solid #4f4f4f;
}

.small-services-title {
  text-align: center;
  color: #0c6efc;
  font-size: 18px;
  text-transform: uppercase;
}

.big-services-title {
  text-align: center;
  font-size: 56px;
  margin-top: 1.5rem;
}

.earth {
  height: 45vh;
}

.l-tsm {
  display: flex;
  padding-left: 6rem;
  padding-right: 6rem;
  margin-top: 12rem;
  justify-content: space-evenly;
}

.l-tsm-header {
  font-size: 36px;
  margin-bottom: 2rem;
}

.l-tsm p {
  font-size: 18px;
}

.l-tsm-text {
  width: 30vw;
}

.hat {
  height: 30vh;
  padding-left: 1rem;
}

.ed-tech {
  display: flex;
  padding-left: 6rem;
  padding-right: 6rem;
  margin-top: 12rem;
  justify-content: space-evenly;
}

.ed-tech p {
  font-size: 18px;
}

.ed-tech h1 {
  font-size: 36px;
  margin-bottom: 2rem;
}

.ed-tech-text {
  width: 30vw;
}

.deliverology {
  background-color: #151618;
  padding-top: 6rem;
  padding-bottom: 8rem;
}

.d-title {
  text-align: center;
  font-size: 56px;
  margin-bottom: 6rem;
}

.d-content {
  display: flex;
  padding-top: 6rem;
  padding-bottom: 6rem;
  justify-content: space-between;
}

.d-content p {
  font-size: 18px;
}

.d-subheading {
  font-size: 28px;
  margin-bottom: 2rem;
  color: #0c6efc;
}

.d-text {
  width: 28vw;
  padding-left: 24rem;
}

.d-img {
  width: 40vw;
}

.tech {
  padding-top: 6rem;
  padding-bottom: 12rem;
  background-color: #1a1b1e;
  border-top: 1px solid #4f4f4f;
  border-bottom: 1px solid #4f4f4f;
}

.tech p {
  font-size: 20px;
}

.small-tech-title {
  text-align: center;
  color: #0c6efc;
  font-size: 18px;
  text-transform: uppercase;
}

.big-tech-title {
  text-align: center;
  font-size: 56px;
  margin-top: 1.5rem;
  margin-bottom: 12rem;
}

p {
  font-size: 18px;
}

.t-first {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.t-first p {
  font-size: 20px;
}

.t-img-1 {
  width: 30vw;
}

.t-text {
  width: 28vw;
}

.t-second {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 12rem;
}

.t-text-2 {
  width: 28vw;
}

footer {
  background-color: #151618;
  padding-top: 6rem;
  padding-bottom: 3rem;
  padding-right: 12rem;
  padding-left: 12rem;
  display: flex;
  border-top: 1px solid #4f4f4f;
  justify-content: space-between;
}

.footer-img {
  width: 10vw;

}

.bg {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
}
#static-rain {
  width: 100%;
  height: 100vh;
}


#canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}
.h-screen{
    height: 100vh;
}
.w-screen{
    width: 100vw;
}